<template>
    <div>
        <div class="chatbot__message-window" ref="scrollDown">
            <ul class="chatbot__messages">
                
                <li v-for="(item, key) in kontenHistoriChat" :key="key" :class="item.posisi=='kiri'?'is-ai animation':'is-user animation'">
                    <span class="chatbot__arrow chatbot__arrow--left" v-if="item.posisi=='kiri'"></span>
                    <p class='chatbot__message'>{{ item.chat }}</p>
                    <span class='chatbot__arrow chatbot__arrow--right' v-if="item.posisi=='kanan'"></span>
                </li>

            </ul>
        </div>
        <div class="chatbot__entry chatbot--closed">
            <input type="text" class="chatbot__input" placeholder="Write a message..." v-model="kontenMsg" v-on:keyup.enter="onEnter"/>
            <i class="ri-send-plane-2-fill icon-size-25 cursor-pointer" @click="sendMsg"></i>
        </div>
    </div>   
</template>

<script>
import axios from "axios";
import moment from "moment-timezone";

export default {
    name: "TemplateChat",
    props: ['deviceid','idakun','serviceid'], 
    components: {},
    data() {
        return {
            id_akun : null,
            device_id: null,
            service_id: null,
            kontenMsg: null,
            kontenHistoriChat: []
        };
    },
    created() {
      this.interval = setInterval(() => this.getNewChat(), 1000);
    },
    beforeDestroy() {
      clearInterval(this.interval)
    },
    mounted() {
        this.id_akun = this.idakun
        this.device_id = this.deviceid
        this.service_id = this.serviceid
        this.getPesanHistori()
    },
    methods: {
        scrollDown(){
            let elHeight = this.$refs.scrollDown;
            if (elHeight) {
                this.$nextTick(() => {
                    elHeight.scrollTop = elHeight.scrollHeight
                })
            }
            
        },
        async getNewChat(){
          try {
              const reqNewChat = await axios.put(`${process.env.VUE_APP_API_URL}chat/balas`, {
                idakun: this.$session.get('id')
              });
              const resdata = reqNewChat.data
           
              if (resdata.results.length > 0) {
                const updatePesan = await axios.put(`${process.env.VUE_APP_API_URL}chat/balas/update`, {
                  deviceid: resdata.results[0].deviceId,
                  id_akun: this.$session.get('id')
                })
                const resdataUpdate = updatePesan.data
                
                if (resdataUpdate.status == 1) {
                  if (resdataUpdate.results.changedRows != 0) {
                    for (let i = 0; i < resdata.results.length; i++) {
                      this.kontenHistoriChat.push({posisi: "kiri", chat: resdata.results[i].chat})
                    }
                    this.$nextTick(async () => {
                        this.scrollDown()
                    })
                  }
                }
              }
            } catch (error) {
              console.log(error);
            }
        },
        async getPesanHistori(){
            try {
                this.kontenHistoriChat = [];

                const response = await axios.put(`${process.env.VUE_APP_API_URL}chat/histori`, {
                    deviceid: this.device_id,
                    idakun: this.id_akun,
                });
               
                let res = response.data;
                if (res.status == 0) {
                    console.log(res.results);
                } else {
                    this.kontenHistoriChat = res.results
                }          
                
                if (this.kontenHistoriChat.length > 0) {
                    this.$nextTick(() => {
                        this.scrollDown()
                        this.$root.$emit('incrementNewPesanEvent')
                    })
                }
            } catch (error) {
                console.log(error);
            }
        },
        sendMsg(){
            const pesan = this.kontenMsg
            this.kontenMsg = null
            this.kontenHistoriChat.push({posisi: "kanan", chat: pesan})
            
            this.$nextTick(async () => {
                this.scrollDown()
                await this.simpanLogChat(pesan)
            })
        },
        onEnter: function() {
          const pesan = this.kontenMsg
          this.kontenMsg = null
            this.kontenHistoriChat.push({posisi: "kanan", chat: pesan})
            this.$nextTick(async () => {
                this.scrollDown()
                await this.simpanLogChat(pesan)
            })
        },
        async simpanLogChat(pesan){
            try {
              const sendParams = {
                posisi: "kanan",
                chat: pesan,
                deviceId: this.device_id,
                id_akun: this.id_akun,
                createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
                status: 'new',
                serviceid: this.service_id
              }
              if (pesan != null) {
                const response = await axios.post(`${process.env.VUE_APP_API_URL}chat/add_log`, sendParams);
                
                let res = response.data;
                if (res.status == 1) {
                    console.log(res);
                } else {
                    console.log(res);
                }          
                
                if (this.kontenHistoriChat.length > 0) {
                    this.$nextTick(() => {
                        this.scrollDown()
                    })
                }
              }else{
                console.log('tidak ada chat...');
              }
            } catch (error) {
              console.log(error);
            }
        }
    },
};
</script>

<style>
.chatbot {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -6px 99px -17px rgba(0, 0, 0, 0.68);
}
.icon-size-25{
    font-size: 20pt;
    color: #292460;
}
.icon-size-15{
    font-size: 15pt;
}
.cursor-pointer{
    cursor: pointer;
}
@media screen and (min-width: 640px) {
  .chatbot {
    max-width: 420px;
    right: 30px;
    top: auto;
  }
}
.chatbot.chatbot--closed {
  top: auto;
  width: 100%;
}

.chatbot__header {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #292460;
  height: 54px;
  padding: 0 20px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.chatbot__header:hover {
  background-color: #393285;
}
.chatbot__header p {
  margin-right: 20px;
}

.chatbot__close-button {
  fill: #fff;
}
.chatbot__close-button.icon-speech {
  width: 20px;
  display: none;
}
.chatbot--closed .chatbot__close-button.icon-speech {
  display: block;
}
.chatbot__close-button.icon-close {
  width: 14px;
}
.chatbot--closed .chatbot__close-button.icon-close {
  display: none;
}

.chatbot__message-window {
  height: calc(100% - (54px + 60px));
  padding: 40px 20px 20px;
  background-color: #fff;
  overflow-x: auto;
  overflow-y: auto;
}
@media screen and (min-width: 640px) {
  .chatbot__message-window {
    height: 380px;
  }
}
.chatbot__message-window::-webkit-scrollbar {
  display: block;
}
.chatbot--closed .chatbot__message-window {
  display: none;
}

.chatbot__messages {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: auto;
}
.chatbot__messages li {
  margin-bottom: 20px;
}
.chatbot__messages li.is-ai {
  display: inline-flex;
  align-items: flex-start;
}
.chatbot__messages li.is-user {
  text-align: right;
  display: inline-flex;
  align-self: flex-end;
}
.chatbot__messages li .is-ai__profile-picture {
  margin-right: 8px;
}
.chatbot__messages li .is-ai__profile-picture .icon-avatar {
  width: 40px;
  height: 40px;
  padding-top: 6px;
}

.chatbot__message {
  display: inline-block;
  padding: 12px 20px;
  word-break: break-word;
  margin: 0;
  border-radius: 6px;
  letter-spacing: -0.01em;
  line-height: 1.45;
  overflow: hidden;
}
.is-ai .chatbot__message {
  background-color: #f0f0f0;
  margin-right: 30px;
}
.is-user .chatbot__message {
  background-color: #f1f0f0;
  margin-left: 30px;
}
.chatbot__message a {
  color: #7226e0;
  word-break: break-all;
  display: inline-block;
}
.chatbot__message p:first-child {
  margin-top: 0;
}
.chatbot__message p:last-child {
  margin-bottom: 0;
}
.chatbot__message button {
  background-color: #fff;
  font-weight: 300;
  border: 2px solid #7226e0;
  border-radius: 50px;
  padding: 8px 20px;
  margin: -8px 10px 18px 0;
  transition: background-color 0.2s ease;
  cursor: pointer;
}
.chatbot__message button:hover {
  background-color: #f2f2f2;
}
.chatbot__message button:focus {
  outline: none;
}
.chatbot__message img {
  max-width: 100%;
}
.chatbot__message .card {
  background-color: #fff;
  text-decoration: none;
  overflow: hidden;
  border-radius: 6px;
  color: black;
  word-break: normal;
}
.chatbot__message .card .card-content {
  padding: 20px;
}
.chatbot__message .card .card-title {
  margin-top: 0;
}
.chatbot__message .card .card-button {
  color: #7226e0;
  text-decoration: underline;
}

.animation:last-child {
  -webkit-animation: fadein 0.25s;
          animation: fadein 0.25s;
  -webkit-animation-timing-function: all 200ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
          animation-timing-function: all 200ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.chatbot__arrow {
  width: 0;
  height: 0;
  margin-top: 18px;
}

.chatbot__arrow--right {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #f1f0f0;
}

.chatbot__arrow--left {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid #f0f0f0;
}

.chatbot__entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 20px;
  border-top: 1px solid #e6eaee;
  background-color: #fff;
}
.chatbot--closed .chatbot__entry {
  display: none;
}

.chatbot__input {
  height: 100%;
  width: 80%;
  border: 0;
}
.chatbot__input:focus {
  outline: none;
}
.chatbot__input::-webkit-input-placeholder {
  color: #7f7f7f;
}
.chatbot__input::-moz-placeholder {
  color: #7f7f7f;
}
.chatbot__input::-ms-input-placeholder {
  color: #7f7f7f;
}
.chatbot__input::-moz-placeholder {
  color: #7f7f7f;
}

.chatbot__submit {
  fill: #7226e0;
  height: 22px;
  width: 22px;
  transition: fill 0.2s ease;
  cursor: pointer;
}
.chatbot__submit:hover {
  fill: #45148c;
}

.u-text-highlight {
  color: #7ee0d2;
}

.loader {
  margin-bottom: -2px;
  text-align: center;
  opacity: 0.3;
}

.loader__dot {
  display: inline-block;
  vertical-align: middle;
  width: 6px;
  height: 6px;
  margin: 0 1px;
  background: black;
  border-radius: 50px;
  -webkit-animation: loader 0.45s infinite alternate;
          animation: loader 0.45s infinite alternate;
}
.loader__dot:nth-of-type(2) {
  -webkit-animation-delay: 0.15s;
          animation-delay: 0.15s;
}
.loader__dot:nth-of-type(3) {
  -webkit-animation-delay: 0.35s;
          animation-delay: 0.35s;
}

@-webkit-keyframes loader {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}

@keyframes loader {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
    margin-top: 10px;
    margin-bottom: 0;
  }
  to {
    opacity: 1;
    margin-top: 0;
    margin-bottom: 10px;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
    margin-top: 10px;
    margin-bottom: 0;
  }
  to {
    opacity: 1;
    margin-top: 0;
    margin-bottom: 10px;
  }
}

input {
  font-family: "Open Sans", sans-serif;
}

strong {
  font-weight: 600;
}

.intro {
  display: block;
  margin-bottom: 20px;
}
</style>