<template>
  <div class="container">
    <div class="col-md-12 text-center p-3">
      <h1>Privacy - <b>Police</b></h1>
    </div>
    <div class="card mt-3 bg-white">
      <div class="card-body overflow-auto">
        <div class="text-center font-weight-bold h5">
          PERSYARATAN DAN KETENTUAN
          <br />
          PENGGUNAAN APLIKASI ELEKTRONIK SISTEM MONITOR PERKAPALAN
        </div>

        <br />
        1. KapalPintar adalah unit kerja dibawah PT Pintar Inovasi Mandiri.<br />
        2. Aplikasi MONITOR PERKAPALAN SECARA ONLINE yang disebut sebagai
        KapalPintar,<br />
        merupakan sistem yang dibangun untuk memfasilitasi proses pemantauan
        PERKAPALAN.<br />
        3. Pengguna KapalPintar adalah perorangan/badan usaha yang memiliki hak
        akses kepada aplikasi KapalPintar,<br />
        direpresentasikan oleh User ID dan password yang diberikan oleh Pihak
        Pengembang aplikasi dalam hal ini KapalPintar.<br />
        4. User ID adalah nama atau pengenal unik sebagai identitas diri dari
        Pengguna yang digunakan untuk beroperasi di dalam aplikasi
        KapalPintar.<br />
        5. Password adalah kumpulan karakter atau string yang digunakan oleh
        Pengguna untuk memverifikasi User ID kepada aplikasi KapalPintar.<br />
        6. User ID dan password yang masih aktif dapat digunakan oleh Pengguna
        untuk menggunakan aplikasi KapalPintar.<br />
        7. Pengguna dapat mengganti password sesuai dengan keinginannya, dan
        menjaganya agar selalu bersifat rahasia.<br />
        8. Waktu yang digunakan untuk proses pengisian melalui website
        KapalPintar adalah waktu dari server KapalPintar.<br />
        9. Layanan aplikasi KapalPintar melalui jaringan internet tersedia
        selama 24 jam 7 hari seminggu.<br />
        10. Semua prosedur pengisian permohonan melalui KapalPintar dianggap
        sah, otentik, asli dan benar<br />
        serta memberikan efek yang sama sebagaimana bila hal tersebut dilakukan
        secara tertulis dan atau melalui dokumen tertulis.<br />
        11. Dengan mengikuti panduan pengisian melalui KapalPintar,<br />
        pengguna setuju data yang diunggah ke aplikasi akan dipergunakan oleh
        para pihak yang terlibat dalam proses pengisian<br />
        sesuai hak aksesnya berdasarkan ketentuan yang berlaku.<br />
        12. Dengan menjadi Pengguna KapalPintar maka Pengguna dianggap telah
        memahami/mengerti dan menyetujui semua isi<br />
        di dalam Persyaratan dan Ketentuan Penggunaan Sistem Aktivasi Lacak
        PERKAPALAN<br />
        Secara Online, Panduan Pengguna, dan ketentuan/peraturan terkait lainnya
        yang berlaku.<br /><br />

        <div class="text-center h6">KEANGGOTAAN PENGGUNA</div>
        1. Registrasi Pengguna<br />
        <span class="tab"></span>a. Perusahaan / Pemilik Kapal melakukan
        registrasi secara online pada aplikasi elektronik KapalPintar<br />
        <span class="tab"></span>dengan memasukkan data-data yang diperlukan
        sehingga mendapatkan notifikasi email untuk masuk login.<br />
        <span class="tab"></span>b. Perusahaan / Pemilik Kapal hanya
        diperkenankan memiliki 1 (satu) User ID dan password.<br />
        <span class="tab"></span>c. Dengan membuat dan/atau mendaftar sebagai
        dalam KAPAL PINTAR, maka Pemilik Kapal telah memberikan persetujuannya
        pada Pakta Integritas.<br />
        2. Kewajiban Pengguna<br />
        <!-- a. Memenuhi ketentuan peraturan perundang-undangan dan kebijakan yang berlaku dalam pelaksaan perizinan sesuai PERMEN 10 Tahun 2019 tentang SPKP.<br /> -->
        <span class="tab"></span>a. Setiap Pengguna bertanggungjawab melindungi
        kerahasiaan hak akses, dan aktivitas lainnya pada KapalPintar.<br />
        <span class="tab"></span>b. Setiap penyalahgunaan hak akses oleh pihak
        lain menjadi tanggung jawab pemilik User ID dan password.<br />
        <span class="tab"></span>c. Pengguna Aplikasi setuju untuk segera
        memberitahukan kepada Pengelola Aplikasi apabila mengetahui adanya
        penyalahgunaan User ID miliknya<br />
        <span class="tab"></span>oleh pihak lain yang tidak berhak atau jika ada
        gangguan keamanan atas User ID miliknya itu<br />
        3. Ketentuan Pengguna<br />
        <span class="tab"></span>a. Pengguna setuju bahwa transaksi yang
        dilakukan melalui KAPAL PINTAR tidak boleh melanggar peraturan
        perundang-undangan yang berlaku di Indonesia.<br />
        <span class="tab"></span>b. Pengguna wajib tunduk dan taat pada semua
        peraturan yang berlaku di Indonesia yang berhubungan<br />
        <span class="tab"></span>dengan penggunaan jaringan dan komunikasi data
        baik di wilayah Indonesia maupun dari dan keluar wilayah Indonesia<br />
        <span class="tab"></span>melalui aplikasi elektronik KapalPintar
        Kementerian Kelautan dan Perikanan.<br />
        <span class="tab"></span>c. Pengguna bertanggungjawab penuh atas isi
        transaksi yang dilakukan dengan menggunakan KapalPintar.<br />
        <span class="tab"></span>d. Pengguna dilarang saling mengganggu proses
        transaksi dan/atau layanan lain yang dilakukan dalam KapalPintar.<br />
        <span class="tab"></span>e. Pengguna setuju bahwa usaha untuk
        memanipulasi data, mengacaukan sistem elektronik dan jaringannya adalah
        tindakan melanggar hukum.<br />
        <span class="tab"></span>f. Pengguna KapalPintar tidak diperkenankan
        untuk menyalahgunakan data yang terunggah pada aplikasi.<br />
        4. Pembatalan Keanggotaan Pengguna<br />
        <span class="tab"></span>a. Pengelola KapalPintar Kementerian Kelautan
        dan Perikanan berhak menunda/menghalangi sementara/membatalkan<br />
        <span class="tab"></span>hak akses Pengguna apabila ditemukan adanya
        informasi/transaksi/aktivitas lain yang tidak dibenarkan sesuai
        ketentuan yang berlaku.<br />
        <span class="tab"></span>b. Pengguna mengundurkan diri dengan cara
        mengirimkan surat permohonan dan disampaikan<br />
        <span class="tab"></span>kepada pengelola KapalPintar Kementerian
        Kelautan dan Perikanan (tempat Pengguna terdaftar)<br />
        <span class="tab"></span>yang dapat dikirimkan melalui sarana elektronik
        (email).<br /><br />

        <div class="text-center h6">TANGGUNG JAWAB DAN AKIBAT</div>
        1. Direktorat Jenderal Pengawasan Sumber Daya Kelautan dan Perikanan
        Kementerian Kelautan dan Perikanan tidak bertanggung jawab<br />
        atas semua akibat karena keterlambatan/kesalahan/kerusakan penerimaan
        data pengisian yang terjadi pada KapalPintar yang dilakukan Pengguna dan
        pihak lain.<br />
        2. Direktorat Jenderal Pengawasan Sumber Daya Kelautan dan Perikanan
        Kementerian Kelautan dan Perikanan tidak bertanggung jawab<br />
        atas semua akibat adanya gangguan infrastruktur yang berakibat pada
        terganggunya proses penggunaan KapalPintar.<br />
        3. Direktorat Jenderal Pengawasan Sumber Daya Kelautan dan Perikanan
        Kementerian Kelautan dan Perikanan tidak bertanggung jawab<br />
        atas segala akibat penyalahgunaan yang dilakukan oleh Pengguna atau
        pihak lain.<br />
        4. Direktorat Jenderal Pengawasan Sumber Daya Kelautan dan Perikanan
        Kementerian Kelautan dan Perikanan tidak menjamin aplikasi elektronik
        KapalPintar<br />
        berlangsung terus secara tepat, handal/tanpa adanya gangguan.<br />
        5. Direktorat Jenderal Pengawasan Sumber Daya Kelautan dan Perikanan
        Kementerian Kelautan dan Perikanan<br />
        berusaha terus meningkatkan dan memperbaiki performance aplikasinya.<br />
        6. Direktorat Jenderal Pengawasan Sumber Daya Kelautan dan Perikanan
        Kementerian Kelautan dan Perikanan dapat membantu pengguna elektronik
        KapalPintar<br />
        terkait dengan penyelesaian kesalahan penggunaan atau penyelesaian
        keterbatasan fasilitas aplikasi namun tidak bertanggungjawab<br />
        atas hasil yang diakibatkan oleh tindakannya.<br />
        7. Pengguna bertanggung jawab atas segala resiko dan tidak terbatas pada
        tidak dapat dilanjutkannya proses pengisian<br />
        apabila dalam penggunaan KapalPintar tidak mengindahkan ketentuan
        ini.<br /><br />

        <div class="text-center h6">PERSELISIHAN</div>
        Perselisihan yang terjadi antara Pengguna dan Pengelola Aplikasi
        elektronik KapalPintar Kementerian Kelautan dan Perikanan
        diselesaikan<br />
        melalui musyawarah untuk mufakat. Apabila musyawarah tidak dapat
        mencapai mufakat, pengguna dan Pengelola Aplikasi Kementerian Kelautan
        dan Perikanan<br />
        sepakat untuk membawa kasus tersebut ke pengadilan yang berada di
        wilayah Indonesia.<br /><br />

        <div class="text-center h6">HAK CIPTA</div>
        1. Pengguna atau pihak lain dilarang mengutip atau meng-copy sebagian
        atau seluruh isi yang terdapat di dalam aplikasi KapalPintar<br />
        tanpa ijin tertulis dari Direktorat Jenderal Pengawasan Sumber Daya
        Kelautan dan Perikanan Kementerian Kelautan dan Perikanan.<br />
        Pelanggaran atas ketentuan ini akan dituntut dan digugat berdasarkan
        peraturan hukum pidana dan perdata yang berlaku di Indonesia.<br />
        2. Pengguna setuju tidak akan dengan cara apapun memanfaatkan,
        memperbanyak, atau berperan dalam penjualan/menyebarkan setiap isi<br />
        yang diperoleh dari KapalPintar untuk kepentingan pribadi dan/atau
        komersial.<br /><br />

        <div class="text-center h6">PERUBAHAN</div>
        1. Pengelola Aplikasi elektronik KapalPintar Kementerian Kelautan dan
        Perikanan berhak/dapat menambah, mengurangi, memperbaiki fasilitas<br />
        yang disediakan aplikasi ini setiap saat, dengan atau tanpa
        pemberitahuan sebelumnya.<br />
        2. Setiap penggunaan oleh pengguna merupakan penegasan terhadap janji
        pengguna untuk terikat dan tunduk kepada ketentuan<br />
        yang telah diperbaiki/ditambah/dikurangi itu. Apabila pengguna tidak
        menginginkan keterikatan tersebut, anggota dapat mengajukan<br />
        keberatan dan mengundurkan diri dari keikutsertaannya sebagai
        anggota/pengguna aplikasi KapalPintar.<br />
        3. Dengan maupun tanpa alasan, Direktorat Jenderal Pengawasan Sumber
        Daya Kelautan dan Perikanan Kementerian Kelautan dan Perikanan<br />
        berhak menghentikan penggunaan KapalPintar tanpa menanggung kewajiban
        apapun kepada pengguna apabila penghentian operasional ini terpaksa
        dilakukan.<br /><br />

        <div class="text-center h6">LAIN-LAIN</div>
        1. Pengguna masih memiliki kewajiban untuk membuat, mencetak, dan
        menyimpan semua Berita Acara ataupun dokumen tertulis lainnya<br />
        yang dipersyaratkan dalam proses perizinan SKAT yang belum terfasilitasi
        dalam aplikasi ini.<br />
        2. Proses pengisian secara elektronik yang berlaku adalah keseluruhan
        proses yang terdapat dalam fitur aplikasi KapalPintar dan
        perubahannya.<br />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolice",
};
</script>

<style>
</style>